<template>
  <section>
    <b-card>
      <vue-good-table
        class="mt-3"
        mode="remote"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :rtl="isRtl"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
        }"
        style-class="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span>
            <span v-if="props.column.field !== 'status'">{{ props.formattedRow[props.column.field] }}</span>
          </span>
        </template>
  
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <table-pagination
            :per-page="perPage"
            :total="total"
            @perPageChanged="
              (value) => props.perPageChanged({ currentPerPage: value })
            "
            @pageChanged="
              (value) => props.pageChanged({ currentPage: value })
            "
          />
        </template>
      </vue-good-table>
    </b-card>
  </section>
</template>
  
<script>
import { BCard } from "bootstrap-vue";
import { GOODTABLE_ISO_DATETIME_INPUT_FORMAT, GOODTABLE_LOCALISED_DATE_FORMAT } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { matchStatus } from '@models';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import trainingsService  from "@/services/trainingsService";
import { mapGetters } from 'vuex';
import { makeErrorToast } from "@/libs/utils";
import { STATUS_COLOR } from "@/libs/utils";
import { trainingStatusDisplay } from '@models';

export default {
  name: 'TrainingLaunchHistory',
  components: {
    BCard,
    VueGoodTable,
    TablePagination
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      perPage: 10,
      page: 1,
      bulkSelection: [],
      columns: [
        {
          label: "Date",
          field: "created_at",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
          width: "6em",
        },
        {
          label: "Training",
          field: 'program_training_user.training.course_name',
          width: "10em",
          sortable: false
        },
        {
          label: "Status",
          field: 'status_id',
          formatFn: this.formatTrainingStatus,
          width: "6em",
          sortable: false
        },
        {
          label: "Time Tracked (sec)",
          field: "total_seconds_tracked",
          tdClass: "text-center",
          width: "6em",
          sortable: false
        },
        {
          label: "First Accessed On",
          field: "first_access_date",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
          width: "10em",
          sortable: false
        },
        {
          label: "Last Access On",
          field: 'last_access_date',
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
          width: "10em",
          sortable: false
        },
        {
          label: "Completed On",
          field: "completed_date",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
          width: "10em",
          sortable: false
        },
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      total: 0,
      STATUS_COLOR,
      matchStatus,
      matchUsers: [],
      comparePercentage: 0
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },
  created() {
    this.loadItems();
  },
  methods: {
    formatTrainingStatus(args) {
      return trainingStatusDisplay[args];
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
  
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
  
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
  
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "status",
        "started_at",
        "ended_at",
        "user.0.full_name",
        "user.1.full_name",
        "compat",
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'compat') {
            columnFilters.push({
              field: 'compatibility',
              value: params.columnFilters[col],
            });
          } else if (col === "started_at" || col === "ended_at") {
            const dateRange = params.columnFilters[col].split(" to ");
            if (dateRange.length > 1) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
            
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const userId = this.$route.params.participantId;
        const response = await trainingsService.getTrainingLaunchHistories(programId, userId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      } 
    }
  },
};
</script>
  
<style lang="scss">
  @import '@/assets/scss/vue/libs/vue-good-table.scss';
  .nav .dropdown-toggle:not(.active)::after {
    background-image: none !important;
  }
</style>
